<template>
	<div class="about-page">
		<div>关于</div>
		<br />
		<div
			contenteditable="true"
			class="edit-block"
			style="margin: 5px 0"
			v-html="text"
		></div>
		<!-- 
        <div contenteditable="true" class="edit-block" style="margin:5px 0;">
            <TBS/>加粗标签<TBE/>普通文本<TIS/>斜体标签<TIE/>
        </div>
        <div contenteditable="true" class="edit-block" style="margin:5px 0;">
            <img content="TB" tag="TBS"/>加粗标签<img content="TB" tag="TBE"/>普通文本<img content="TI" tag="TIS"/>斜体标签<img content="TI" tag="TIE"/>
        </div> -->
		<el-button type="primary" @click="setStyle('bold')">加粗</el-button>
		<el-button type="primary" @click="setStyle('italic')">斜体</el-button>
		<el-button type="primary" @click="setStyle('underline')">下划线</el-button>
		<el-button type="primary" @click="setRed">红色</el-button>
		<el-button type="primary" @click="setSuperscript">上标</el-button>
		<el-button type="primary" @click="setSubscript">下标</el-button>
		<el-button type="primary" @click="setInsertText">插入内容</el-button>
		<el-button type="primary" @click="setBlock">添加block标签</el-button>
		<el-button
			type="primary"
			@click="currentPage-- < 1 ? (currentPage = 1) : currentPage--"
			>上一页</el-button
		>
		<el-button
			type="primary"
			@click="
				currentPage++ > pageCount
					? (currentPage = pageCount)
					: currentPage++
			"
			>下一页</el-button
		>
		<div style="display: flex">
			<div style="width: 750px; height: 790px; overflow: auto">
				<pdf
					ref="pdfDom"
					:src="pdfSrc"
					:page="currentPage"
					@num-pages="pageCount = $event"
					@page-loaded="currentPage = $event"
					@loaded="loadPdfHandler"
				></pdf>
			</div>
			<div style="width: 750px; height: 790px; overflow: auto">
				<div v-for="i in numPages" :key="i" :data-pid="`p-${i}`">
					<pdf :src="pdfSrc" :page="i"></pdf>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import pdf from "vue-pdf";
export default {
	components: { pdf },
	data() {
		return {
			pdfSrc: null,
			currentPage: 0, // pdf文件页码
			pageCount: 0, //pdf文件总页数
			numPages: null,
			text: "小程序写菜单切换 以及点击菜单的图片切换。HTML5---contentEditable属性 document.execCommand()函数置文本粗斜体等",
		};
	},
	methods: {
		showLoading() {
			this.$refs.fullLoading.setShow();
		},
		setStyle(cmd) {
			document.execCommand(cmd, false, null);
		},
		setRed() {
			document.execCommand("foreColor", false, "red");
		},
		setSuperscript() {
			document.execCommand("superscript", false, null);
		},
		setSubscript() {
			document.execCommand("subscript", false, null);
		},
		setBlock() {
			document.execCommand("FormatBlock", false, "<dl>");
		},
		setInsertText() {
			document.execCommand("insertText", false, "插入的内容");
		},
		initPdf() {
			let loadingTask = pdf.createLoadingTask(
				"https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/test-ppt-pdf.pdf?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=101636024094&Signature=wg9N2og2qWh7lC6gYL9ge0zgSDc%3D"
			);
			this.pdfSrc = loadingTask;
			loadingTask.promise.then((pdf) => {
				console.log("xxxx:::", pdf);
				this.numPages = pdf.numPages;
			});
		},
		loadPdfHandler(e) {
			this.currentPage = 1; // 加载的时候先加载第一页
		},
	},
	mounted() {
		this.initPdf();
	},
};
</script>
<style lang="scss" scoped>
img {
	width: 25px;
	height: 15px;
	border-radius: 0 10px 10px 0;
	cursor: move;
	background-color: rgb(255, 255, 255);
	outline: none;
	border: none;
	position: relative;
	&::after {
		// position: absolute;
		color: #000;
		content: attr("tag");
		user-select: none;
	}
}

TBS {
	display: inline-block;
	font-size: 10px;
	font-weight: 400;
	background-color: rgb(95, 0, 248);
	color: #fff;
	padding: 2px 4px;
	margin: 0 2px;
	border-radius: 0 10px 10px 0;
	cursor: move;
	-webkit-transform-origin-x: 0;
	transform: scale(0.9);
	pointer-events: none;
	// user-select: none;
	&::before {
		content: "TB";
		user-select: none;
	}
}
TBE {
	position: relative;
	display: inline-block;
	height: 10px;
	width: 20px;
	font-size: 10px;
	font-weight: 400;
	background-color: rgb(95, 0, 248);
	color: #fff;
	padding: 2px 4px;
	margin: 0 2px;
	// border-radius: 10px 0 0 10px;
	// cursor: move;
	-webkit-transform-origin-x: 0;
	transform: scale(0.9);
	// pointer-events: none;
	&::before {
		content: "TB";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		text-align: center;
		user-select: none;
		pointer-events: none;
		cursor: move;
	}
}
TIS {
	display: inline-block;
	font-size: 10px;
	font-weight: 400;
	background-color: rgb(0, 149, 248);
	color: #fff;
	padding: 2px 4px;
	margin: 0 2px;
	border-radius: 0 10px 10px 0;
	cursor: move;
	-webkit-transform-origin-x: 0;
	transform: scale(0.9);
	&::before {
		content: "TI";
		user-select: none;
	}
}
TIE {
	display: inline-block;
	font-size: 10px;
	font-weight: 400;
	background-color: rgb(0, 149, 248);
	color: #fff;
	padding: 2px 4px;
	margin: 0 2px;
	border-radius: 10px 0 0 10px;
	cursor: move;
	-webkit-transform-origin-x: 0;
	transform: scale(0.9);
	&::before {
		content: "TI";
		user-select: none;
	}
}
TB {
	font-weight: bold;
	&::before {
		content: "TB";
		display: inline-block;
		font-size: 10px;
		font-weight: 400;
		background-color: rgb(95, 0, 248);
		color: #fff;
		padding: 2px 4px;
		margin: 0 2px;
		border-radius: 0 10px 10px 0;
		cursor: move;
		-webkit-transform-origin-x: 0;
		transform: scale(0.9);
	}
	&::after {
		content: "TB";
		display: inline-block;
		font-size: 10px;
		font-weight: 400;
		background-color: rgb(95, 0, 248);
		color: #fff;
		padding: 2px 4px;
		margin: 0 2px;
		border-radius: 10px 0 0 10px;
		cursor: move;
		-webkit-transform-origin-x: 0;
		transform: scale(0.9);
	}
}
TI {
	font-style: italic;
	&::before {
		content: "TI";
		display: inline-block;
		font-size: 10px;
		font-style: normal;
		background-color: rgb(95, 0, 248);
		color: #fff;
		padding: 2px 4px;
		margin: 0 2px;
		border-radius: 0 10px 10px 0;
		cursor: move;
		-webkit-transform-origin-x: 0;
		transform: scale(0.9);
	}
	&::after {
		content: "TI";
		display: inline-block;
		font-size: 10px;
		font-style: normal;
		background-color: rgb(95, 0, 248);
		color: #fff;
		padding: 2px 4px;
		margin: 0 2px;
		border-radius: 10px 0 0 10px;
		cursor: move;
		-webkit-transform-origin-x: 0;
		transform: scale(0.9);
	}
}
</style>
